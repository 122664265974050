import React from "react";
import { useEffect, useState, useCallback } from "react";
import {
  getBalanceInEth,
  connectWallet,
  disconnectWallet,
  getCurrentWalletConnected,
} from "./util/interactGeneral";
import {
  wwcTournamentContract,
  updateMessage,
  loadCurrentMessage,
} from "./util/interactMessage";
import './pages.css';

const MessagePage = () => {
  //state variables
  const [walletAddress, setWallet] = useState("");
  const [walletBalance, setWalletBalance] = useState(0);
  const [status, setStatus] = useState("");
  const [message, setMessage] = useState("No connection to the network."); //default message
  const [newMessage, setNewMessage] = useState("");

  const addSmartContractListenerCallback = useCallback(() => {
    wwcTournamentContract.events.UpdatedMessage({}, (error, data) => {
      if (error) {
        setStatus("😥 " + error.message)
        console.log('addSmartContractListener error: ' + error.message)
      } else {
        setMessage(data.returnValues[1])
        setNewMessage("")
        setStatus("🎉 Your message has been updated!")
        console.log('addSmartContractListener message: ' + message)
      }
    })
  }, [message]);

  const addWalletListenerCallback = useCallback(() => {
    if (window.ethereum) {
      window.ethereum.on("accountsChanged", async (accounts) => {
        if (accounts.length > 0) {
          const account = accounts[0]
          setWallet(account)
          console.log('addWalletListener walletAddress: ' + walletAddress)
          setWalletBalance(await getBalanceInEth(account))
          console.log('addWalletListener balance: ' + walletBalance)
          setStatus("👆🏽 Write a message in the text-field above.")
          console.log('addWalletListener status: ' + status)
        } else {
          setWallet("")
          console.log('addWalletListener NO walletAddress ! Connect wallet please..')
          setWalletBalance(0)
          setStatus("🦊 Connect to MetaMask using the top right button.")
        }
      })
    } else {
      setStatus(
        <p>
          {" "}
          🦊 <a target="_blank" rel="noreferrer" href={`https://metamask.io/download.html`}>
            You must install MetaMask, a virtual Ethereum wallet, in your browser.
          </a>
        </p>
      )
    }
  }, [status, walletAddress, walletBalance])

  //called only once
  useEffect(() => {
    async function fetchData() {
      const message = await loadCurrentMessage()
      setMessage(message)
      console.log('useEffect message: ' + message)
      addSmartContractListenerCallback()

      const { address, balance, status } = await getCurrentWalletConnected()
      setWallet(address)
      console.log('useEffect address: ' + walletAddress)
      setWalletBalance(balance)
      console.log('useEffect balance: ' + walletBalance)
      setStatus(status)
      console.log('useEffect status: ' + status)

      addWalletListenerCallback()
    }
    fetchData()
  }, [addSmartContractListenerCallback, walletAddress, walletBalance, addWalletListenerCallback]);

  const processWalletResponse = (walletResponse) => {
    setStatus(walletResponse.status)
    console.log('processWalletResponse status: ' + status)
    setWallet(walletResponse.address)
    console.log('processWalletResponse address: ' + walletAddress)   
    setWalletBalance(walletResponse.balance)
    console.log('processWalletResponse balance: ' + walletBalance)
  }
 
  const connectWalletPressed = async () => {
    const walletResponse = await connectWallet()
    processWalletResponse(walletResponse)
  };

  const onUpdatePressed = async () => {
    const { status } = await updateMessage(walletAddress, newMessage)
    setStatus(status)
    console.log('onUpdatePressed status: ' + status)
  };

  const disconnectWalletPressed = async () => {
    const walletResponse = await disconnectWallet()
    processWalletResponse(walletResponse)
  }

  //the UI of our component 
  return (
    <div id="container">
      <h1 className="page-title">Message Page</h1>
      <span>
      <button id="walletButton" onClick={connectWalletPressed}>
        {walletAddress.length > 0 ? (
          "Connected: " +
          String(walletAddress).substring(0, 6) +
          "..." +
          String(walletAddress).substring(38)
        ) : (
          <span>Connect Wallet</span>
        )}
      </button><br />
      {walletAddress.length > 0 ? (
        <span>
          Balance: ETH {walletBalance.toFixed(4)}<br />
          <button id="disconnectButton" onClick={disconnectWalletPressed}>Disconect wallet</button>
        </span>

      ) : (
        <span>Not connected</span>
      )}
      </span>

      <h2 style={{ paddingTop: "50px" }}>Current Message (Polygon Mumbai):</h2>
      <p>{message}</p>

      <h2 style={{ paddingTop: "18px" }}>New Message:</h2>

      <div>
        <input
          type="text"
          placeholder="Update the message in your smart contract."
          onChange={(e) => setNewMessage(e.target.value)}
          value={newMessage}
        />
        <p id="status">{status}</p>

        <button id="publish" onClick={onUpdatePressed}>
          Update
        </button>
      </div>
    </div>
  );
};

export default MessagePage;

import React, { useState, useEffect/*, useCallback*/ } from 'react';
import { getCurrentWalletConnected, } from "./util/interactGeneral";
import { getNumroundsForTournament, getAthletesInARound } from './util/interactTournament';
import { setRoundAndNextRound } from './util/interactTournament';

function RoundPage() {
    const [walletAddress, setWallet] = useState("");
    const [status, setStatus] = useState("");
    const [tournamentId, setTournamentId] = useState(parseInt(localStorage.getItem('tournamentId')) || 0);
    const [maxNumRounds, setMaxNumRounds] = useState(0);
    const [numRound, setNumRound] = useState(0);
    const [athleteAddresses, setAthleteAddresses] = useState([]);
    const [selectedWinners, setSelectedWinners] = useState(Array(athleteAddresses.length/2).fill(''));

    /*const addWalletListenerCallback = useCallback(() => {
      if (window.ethereum) {
        window.ethereum.on("accountsChanged", async (accounts) => {
          if (accounts.length > 0) {
            const account = accounts[0]
            setWallet(account)
            console.log('addWalletListener walletAddress: ' + walletAddress)
            //setWalletBalance(await getBalanceInEth(account))
            //console.log('addWalletListener balance: ' + walletBalance)
            //setTotalSupply(await getTotalSupply())
            //console.log('addWalletListener totalSuuply: ' + totalSupply)
            setStatus("👆🏽 Fill in the fields.")
            console.log('addWalletListener status: ' + status)
          } else {
            setWallet("")
            console.log('addWalletListener NO walletAddress ! Connect wallet please..')
            //setWalletBalance(0)
            //setTotalSupply(0)
            setStatus("🦊 Connect to MetaMask using the top right button.")
          }
        })
      } else {
        setStatus(
          <p>
            {" "}
            🦊 <a target="_blank" rel="noreferrer" href={`https://metamask.io/download.html`}>
              You must install MetaMask, a virtual Ethereum wallet, in your browser.
            </a>
          </p>
        )
      }
    }, [status, walletAddress/*, walletBalance, totalSupply* / ])*/
  
    //called only once
    useEffect(() => {
      async function fetchData() {
        try {
          //addSmartContractListenerCallback()
  
          const { address } = await getCurrentWalletConnected()
          setWallet(address)
          console.log('useEffect address: ' + walletAddress)
  
          setStatus("👆🏽 Fill in the fields.")
          console.log('useEffect status: ' + status)

          //addWalletListenerCallback()
        } catch (error) {
          console.log(`Error: ${error}`);
        }
      }
      fetchData()
    }, [walletAddress, status/*, addWalletListenerCallback, addSmartContractListenerCallback*/]);
  




    useEffect(() => {
      if (tournamentId) {
        console.log('Round for tournamentId='+tournamentId);
        getNumroundsForTournament(tournamentId).then(numRounds => {
          console.log('getNumroundsForTournament: '+numRounds);
          setMaxNumRounds(numRounds);
        });
        getAthletesInARound(tournamentId, numRound).then(([_athleteAddresses, _winnerAddresses]) => {
          console.log('getAthletesInARound athleteAdresses: ', _athleteAddresses);
          console.log('getAthletesInARound winnerAdresses: ', _winnerAddresses);
          setAthleteAddresses(_athleteAddresses);
          setSelectedWinners(_winnerAddresses);
        });
      }
    }, [tournamentId, maxNumRounds, numRound]);
  

    const handleSelectionChange = (index, event) => {
        const newSelectedWinners = [...selectedWinners];
        newSelectedWinners[index] = event.target.value;
        setSelectedWinners(newSelectedWinners);
    };

    const saveWinners = () => {
      const roundId = parseInt(numRound);
      console.log('saveWinners walletAddress='+walletAddress+' tournamentId='+tournamentId+' roundId'+roundId+
      ' athleteAddresses: ', athleteAddresses, ' selectedWinners: ', selectedWinners);
      setRoundAndNextRound(walletAddress, tournamentId, roundId, athleteAddresses, selectedWinners);

      // Create or update next round with no winners
      /*if (selectedWinners.length > 1) {
        const nextWinners = new Array(selectedWinners.length / 2).fill(address0);
        setTournamentGamesArray(walletAddress, tournamentId, roundId+1, selectedWinners, nextWinners);
      }*/
    };

    const handleGetAthletesSubmit = (event) => {
      event.preventDefault();
      localStorage.setItem('tournamentId', tournamentId);
    };
  

    return (
      <div id="container">
        <h1>Tournament Round</h1>
        <p>WalletAddress: {walletAddress}</p>
        <form onSubmit={handleGetAthletesSubmit}>
          <label>
            For Tournament Id:
            <input type="number" value={tournamentId} onChange={e => setTournamentId(e.target.value)} min="0" />
          </label>
          <label>
            For Round:
            <input type="number" value={numRound} onChange={e => setNumRound(e.target.value)} min="0" max={maxNumRounds} />
          </label>
          <button type="submit">Get Athletes</button>
        </form>

        <div className="RoundPage">
            {athleteAddresses.map((athleteAddress, index) => 
                index % 2 === 0 ? (
                    <div key={index}>
                        <select value={selectedWinners[index/2]} onChange={(event) => handleSelectionChange(index/2, event)}>
                            <option value="">Select winner</option>
                            <option value={athleteAddress}>{athleteAddress}</option>
                            <option value={athleteAddresses[index + 1]}>{athleteAddresses[index + 1]}</option>
                        </select>
                    </div>
                ) : null
            )}
            <button onClick={saveWinners}>Save this Round and Next one</button>
        </div>
      </div>
    );
}

export default RoundPage;

// GamePage.js
function GamePage() {
  return (
    <div id="container">
      <h1>Game Page</h1>
    </div>
  );
}

export default GamePage;

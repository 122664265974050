import React, { useState } from 'react';
//import { Navbar, Nav, NavDropdown } from 'react-bootstrap';
//import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import Navigation from './Naviagtion';
//import MessagePage from './MessagePage';
//import AdminPage from './AdminPage';
//import CreateTournamentPage from './CreateTournamentPage';
//import ViewAthletesPage from './ViewAthletesPage';
//import EarningPage from './EarningPage';
//import GamePage from './GamePage';
//import RoundPage from './RoundPage';
import './App.css';
//import wkflogo from "./wkflogo.png";
//import AthleteNFTPage from './AthleteNFTPage';

// Create a context
const TournamentContext = React.createContext();

// Create a provider that stores the tournamentId
function TournamentProvider({ children }) {
  const [tournamentId, setTournamentId] = useState(1*localStorage.getItem('tournamentId')); // convert to numeric
  const value = { tournamentId, setTournamentId };
  return <TournamentContext.Provider value={value}>{children}</TournamentContext.Provider>;
}

function App() {



  return (
    <TournamentProvider>
      <Navigation />
    </TournamentProvider>
  );
}

export default App;

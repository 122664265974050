import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import MessagePage from './MessagePage';
import AdminPage from './AdminPage';
import CreateTournamentPage from './CreateTournamentPage';
import ViewAthletesPage from './ViewAthletesPage';
import RoundPage from './RoundPage';
import EarningPage from './EarningPage';
import GamePage from './GamePage';
import AthleteNFTPage from './AthleteNFTPage';
import AboutPage from './Aboutpage';
import ContactPage from './ContactPage';
import './App.css';
import "./navigation.css";
import wkflogo from "./wkflogo.png";

const Navigation = () => {
  const [activeSubMenu, setActiveSubMenu] = useState(null);

  const handleMouseEnter = (submenuId) => {
    setActiveSubMenu(submenuId);
  };

  const handleMouseLeave = () => {
    setActiveSubMenu(null);
  };

  const submenus = [
    {
      id: 1,
      title: 'Admin',
      items: [
        { title: 'Admins', path: '/admin' },
        { title: 'Create Tournament', path: '/createtournament' },
        { title: 'View Athletes', path: '/viewathletes' },
        { title: 'Athlete NFT', path: '/athletenft' }
      ]
    },
    {
      id: 2,
      title: 'Match',
      items: [
        { title: 'Round', path: '/round' },
        { title: 'Earning', path: '/earning' },
        { title: 'Game', path: '/game' }
      ]
    },
    {
      id: 3,
      title: 'Site',
      items: [
        { title: 'About', path: '/about' },
        { title: 'Contact', path: '/contact' },
      ]
    },
  ];

  return (
    <Router>
      <div className="App">
        <img id="logo" className="App-logo" alt="logo" src={wkflogo}></img>
        <nav>
          <ul className="nav-links">
            <li>
              <Link to="/message">Message</Link>
            </li>
            {submenus.map((submenu) => (
              <li
                key={submenu.id}
                onMouseEnter={() => handleMouseEnter(submenu.id)}
                onMouseLeave={handleMouseLeave}
              >
                <Link to={submenu.items[0].path}>{submenu.title}</Link>
                {activeSubMenu === submenu.id && (
                  <ul className="submenu">
                    {submenu.items.map((item, index) => (
                      <li key={index}>
                        <Link to={item.path}>{item.title}</Link>
                      </li>
                    ))}
                  </ul>
                )}
              </li>
            ))}
            <li>
              <Link to="/Connect">Connect</Link>
            </li>
          </ul>
        </nav>

        <Routes>
          <Route path="/message" element={<MessagePage />} />
          <Route path="/admin" element={<AdminPage />} />
          <Route path="/createtournament" element={<CreateTournamentPage />} />
          <Route path="/viewathletes" element={<ViewAthletesPage />} />
          <Route path="/round" element={<RoundPage />} />
          <Route path="/earning" element={<EarningPage />} />
          <Route path="/game" element={<GamePage />} />
          <Route path="/athletenft" element={<AthleteNFTPage />} />
          <Route path="/about" element={<AboutPage />} />
          <Route path="/contact" element={<ContactPage />} />
          <Route path="/" element={<MessagePage />} />
          <Route path="*" element={<MessagePage />} />
        </Routes>
      </div>
    </Router>
  );
};

export default Navigation;

import React, { useState, useEffect, useCallback } from 'react';
import './CreateTournamentPage.css';
import {
  getCurrentWalletConnected,
} from "./util/interactGeneral";
import {
  wwcTournamentContract,
  createTournament,
  //setRoundAndNextRound,
} from "./util/interactTournament";

function CreateTournamentPage() {
  //state variables
  const [walletAddress, setWallet] = useState("");
  //const [walletBalance, setWalletBalance] = useState(0);
  //const [totalSupply, setTotalSupply] = useState(0);
  const [status, setStatus] = useState("");
  const [numRounds, setNumRounds] = useState(parseInt(localStorage.getItem('numRounds')) || 1);
  const [athleteAddresses, setAthleteAddresses] = useState(Array(Math.pow(2, numRounds)).fill(''));
  const [tournamentId, setTournamentId] = useState(parseInt(localStorage.getItem('tournamentId')) || 0);

  const addSmartContractListenerCallback = useCallback(() => {
    wwcTournamentContract.events.TournamentCreated({}, (error, data) => {
      if (error) {
        setStatus("😥 " + error.message);
        console.log('addSmartContractListener TournamentCreated error: ' + error.message);
      } else {
        console.log('TournamentIdCreated event data: ', data);
        const tId = parseInt(data.returnValues[0]);
        console.log('TournamentIdCreated event Id: '+tId);
        setTournamentId(tId);
        localStorage.setItem('tournamentId', tId.toString()); // survives Browser refresh or URL reload / as string
        localStorage.setItem('numRounds', numRounds.toString());
        localStorage.setItem('athleteAddresses', JSON.stringify(athleteAddresses));
        console.log('addSmartContractListener tournamentId: ' , tournamentId);
      }
    })
  }, [tournamentId, athleteAddresses, numRounds, setTournamentId]);

  const addWalletListenerCallback = useCallback(() => {
    if (window.ethereum) {
      window.ethereum.on("accountsChanged", async (accounts) => {
        if (accounts.length > 0) {
          const account = accounts[0]
          setWallet(account)
          console.log('addWalletListener walletAddress: ' + walletAddress)
          //setWalletBalance(await getBalanceInEth(account))
          //console.log('addWalletListener balance: ' + walletBalance)
          //setTotalSupply(await getTotalSupply())
          //console.log('addWalletListener totalSuuply: ' + totalSupply)
          setStatus("👆🏽 Fill in the fields.")
          console.log('addWalletListener status: ' + status)
        } else {
          setWallet("")
          console.log('addWalletListener NO walletAddress ! Connect wallet please..')
          //setWalletBalance(0)
          //setTotalSupply(0)
          setStatus("🦊 Connect to MetaMask using the top right button.")
        }
      })
    } else {
      setStatus(
        <p>
          {" "}
          🦊 <a target="_blank" rel="noreferrer" href={`https://metamask.io/download.html`}>
            You must install MetaMask, a virtual Ethereum wallet, in your browser.
          </a>
        </p>
      )
    }
  }, [status, walletAddress/*, walletBalance, totalSupply*/])

  //called only once
  useEffect(() => {
    async function fetchData() {
      try {
        const adr = localStorage.getItem('athleteAdresses') || [];
        if (adr.length > 0) {
          setAthleteAddresses(adr);
        }

        addSmartContractListenerCallback()

        const { address } = await getCurrentWalletConnected()
        setWallet(address)
        console.log('useEffect address: ' + walletAddress)

        addWalletListenerCallback()
      } catch (error) {
        console.log(`Error: ${error}`);
      }
    }
    fetchData()
  }, [walletAddress, addWalletListenerCallback, addSmartContractListenerCallback]);

  useEffect(() => {
    const numAthletes = Math.pow(2, numRounds);
    if (numAthletes > athleteAddresses.length) {
      setAthleteAddresses(prevAddresses => [...prevAddresses, ...Array(numAthletes - prevAddresses.length).fill('')]);
    } 
  }, [numRounds, athleteAddresses.length]);

  useEffect(() => {
    const numAthletes = Math.pow(2, numRounds);
    if (numAthletes < athleteAddresses.length) {
      setAthleteAddresses(prevAddresses => prevAddresses.slice(0, numAthletes));
    }
  }, [numRounds, athleteAddresses.length]);

  const handleNumRoundsChange = (e) => {
    setNumRounds(e.target.value);
  };

  const handleAthleteAddressChange = (index, e) => {
    const newAddresses = [...athleteAddresses];
    newAddresses[index] = e.target.value;
    setAthleteAddresses(newAddresses);
  };

  const moveUp = (index) => {
    if (index > 0) {
      const newAddresses = [...athleteAddresses];
      [newAddresses[index - 1], newAddresses[index]] = [newAddresses[index], newAddresses[index - 1]];
      setAthleteAddresses(newAddresses);
    }
  };

  const moveDown = (index) => {
    if (index < athleteAddresses.length - 1) {
      const newAddresses = [...athleteAddresses];
      [newAddresses[index + 1], newAddresses[index]] = [newAddresses[index], newAddresses[index + 1]];
      setAthleteAddresses(newAddresses);
    }
  };

  const handleTournamentSubmit = async (e) => {
    e.preventDefault();

    const TxHash = await createTournament(walletAddress, numRounds, athleteAddresses);
    console.log('handleSubmit TxHash: ' , TxHash);
    setStatus('Tx Hash: ' + TxHash);
  };

  // Test addresses
  const predefinedAddresses = [
    "0x4E4D5D47a6eA05bfFf6ECA013f29130aD82FEA6d",
    "0xBeFcB6ba4a9974C0DDcBeDE592811b19a199f988",
    "0xF7Ee26C56dD0962f4F5549869AF452DBbE7686f9",
    "0x50387c6eeBA7A9610f8B6B3d39b505D9c781adE5",
    "0xE1A0935C35f3211919CD05953326b3446ef14d5A",
    "0xF3881806Cdb3061C496F68b2eb331D48945C9551",
    "0x9782156E7CFFbdDAc65aa64450e8950c8A798734",
    "0x13A9954E63ABe8A678377BDb13F619796AEf7780"
  ];  // Replace with your predefined addresses
  function setPredefinedAddresses() {
    setNumRounds(3);
    setAthleteAddresses(predefinedAddresses);
  }

  return (
    <div id="container">
      <div className='scrollable-container'>
      <h1>Create Tournament</h1>
      <p>WalletAddress: {walletAddress}</p>
      <form onSubmit={handleTournamentSubmit} className="tournament-form">
        <div className="form-control">
          <label>
            Number of rounds:
          </label>
          <input type="number" value={numRounds} onChange={handleNumRoundsChange} min="1" max="6" />
        </div>
          {athleteAddresses.map((address, index) => (
            <div key={index} className="address-control">
              <div className="input-control">
                <label>
                  Athlete Wallet address {index + 1}:
                </label>
                <input type="text" value={address} onChange={(e) => handleAthleteAddressChange(index, e)} />
              </div>
              <div className="button-control">
                {index !== 0 && <button type="button" onClick={() => moveUp(index)} className='small-button'>Up</button>}
                {index !== athleteAddresses.length - 1 && <button type="button" onClick={() => moveDown(index)} className='small-button'>Down</button>}
              </div>
            </div>
          ))}
        <div className="form-control">
          <p id="status">{tournamentId} : {status}</p>
          <button type="submit">Send</button>
          <button type="button" onClick={setPredefinedAddresses}>Set Predefined Addresses</button>
        </div>
      </form>
      </div>
    </div>
  );
}

export default CreateTournamentPage;

// AthleteNFT.js
import React, { useState } from 'react';
import axios from 'axios';

function AthleteNFTPage() {
  const [athleteName, setAthleteName] = useState('');
  const [athleteDescription, setAthleteDescription] = useState('');
  const [athleteImageFile, setAthleteImageFile] = useState(null);
  const [imageUrl, setImageUrl] = useState(null);
  const [metadataUrl, setMetadataUrl] = useState(null);
  const [athleteWalletAddress, setAthleteWalletAddress] = useState('');

  const ipfsUrl = process.env.REACT_APP_PINATA_URL;

  const saveIPFS = async (saveData, saveAsImage) => {
    try {
      let ipfsUrl = 'https://api.pinata.cloud/pinning/';
      /*let ipfsHeaders = {
        'pinata_api_key': `${process.env.REACT_APP_PINATA_API_KEY}`,
        'pinata_secret_api_key': `${process.env.REACT_APP_PINATA_API_SECRET}`
      };*/
      let ipfsHeaders = {};
      ipfsHeaders['pinata_api_key'] = process.env.REACT_APP_PINATA_API_KEY;
      ipfsHeaders['pinata_secret_api_key'] = `${process.env.REACT_APP_PINATA_API_SECRET}`;
      let ipfsData;

      console.log('saveIPFS saveData: ', saveData);
      if (saveAsImage) {
        ipfsUrl += 'pinFileToIPFS';
        ipfsHeaders['Content-Type'] = "multipart/form-data";
        ipfsData = new FormData();
        ipfsData.append("file", saveData);
      } else {
        ipfsUrl += 'pinJsonToIPFS';
        ipfsData = saveData;
      }

      console.log('saveIPFS url=' + ipfsUrl);
      console.log('saveIPFS headers: ', ipfsHeaders);
      console.log('saveIPFS data: ', ipfsData);

      const resultIPFS = await axios({
        method: "post",
        url: ipfsUrl,
        data: ipfsData,
        headers: ipfsHeaders,
      });
      const ipfsHash = resultIPFS.data.IpfsHash;
      console.log('saveIPFS: ' + ipfsHash);
      return ipfsHash;
    } catch (error) {
      console.log('saveIPFS Error sending file to IPFS: ', error);
      return '';
    }
  }

  const handleAthleteNFTSubmit = async (e) => {
    e.preventDefault();

    if (athleteImageFile) {
      const athleteImagefileHash = await saveIPFS(athleteImageFile, true);
      setImageUrl(ipfsUrl + athleteImagefileHash);
      if (athleteImagefileHash > '') {
        // Create metadata JSON
        const metadata = {
          name: athleteName,
          description: athleteDescription,
          image: ipfsUrl + athleteImagefileHash
        };

        // Store the metadata in IPFS
        const metadataHash = await saveIPFS(metadata, false);
        setMetadataUrl(ipfsUrl + metadataHash);

        console.log('AthleteImageFile hash:', athleteImagefileHash);
        console.log('Metadata hash:', metadataHash);
        console.log('metadata: ' , metadata)
      };
    }

    // Mint NFT through smart contract
    //const TxHash = await addAdmin(walletAddress, tournamentId, newAdminAddress);
    //console.log('handleAddAdminSubmit TxHash: ' , TxHash);
    //setStatus('Tx Hash: ' + TxHash);
  };

  return (
    <div id="container">
      <h1>AthleteNFT Page</h1>

      <div>
        <form onSubmit={handleAthleteNFTSubmit} className="athletenft-form">
          <div className="input-control">
            <label>
              Athlete Name:
            </label>
            <input
              type="text"
              placeholder="Athlete name"
              value={athleteName}
              onChange={(e) => setAthleteName(e.target.value)}
            />
          </div>
          <div className="input-control">
            <label>
              Athlete athleteDescription:
            </label>
            <input
              type="text"
              placeholder="athlete description"
              value={athleteDescription}
              onChange={(e) => setAthleteDescription(e.target.value)}
            />
          </div>
          <div className="input-control">
            <label>
              Athlete NFT Image:
            </label>
            <input type="file" onChange={(e) => setAthleteImageFile(e.target.files[0])} required />
          </div>
          <div className="input-control">
            <label>
              Athlete Wallet Address:
            </label>
            <input
              type="text"
              placeholder="athlete wallet address"
              value={athleteWalletAddress}
              onChange={(e) => setAthleteWalletAddress(e.target.value)}
            />
          </div>
          <div className="form-control">
            <button type="submit">Save</button>
          </div>
        </form>
        <p>&nbps;</p>
        <div>
          Image: <a href={imageUrl} target="_blank" rel="noopener noreferrer">
            <img src={imageUrl} alt='saved Athlete' width="100" /><br />
            {imageUrl}
          </a>
        </div>
        Metadata: <a href={metadataUrl} target="_blank" rel="noopener noreferrer">{metadataUrl}</a>
      </div>
    </div>
  );
}

export default AthleteNFTPage;

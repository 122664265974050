require("dotenv").config() // only read starting with "REACT_APP_" !
const alchemyKey = process.env.REACT_APP_ALCHEMY_KEY
console.log('Alchemy_key: ' + alchemyKey)
const { createAlchemyWeb3 } = require("@alch/alchemy-web3")
const web3 = createAlchemyWeb3(alchemyKey)
const { showjson, address0 } = require('./interactGeneral')

const wwcTournamentAddress = process.env.REACT_APP_CONTRACT_WWCTOURNAMENT_ADDRESS
console.log('wwcTournamentAddress: ' + wwcTournamentAddress)

const wwcTournamentAbi = require("../contract-abis/wwcTournament-abi.json")
console.log('wwcTournamentAbi: ' + showjson(wwcTournamentAbi))

export const wwcTournamentContract = new web3.eth.Contract (
  wwcTournamentAbi, wwcTournamentAddress
)

export async function fetchAdmins(tournamentId) {
  try {
    console.log('fetchAdmins tournamentId=' + tournamentId);
    const adminList = await wwcTournamentContract.methods.getTournamentAdminList(tournamentId).call();
    console.log('fetchAdmins result: ', adminList);
    return adminList;
  } catch (error) {
      console.error('Error fetching admins:', error);
  }
};

export async function addAdmin(walletAddress, tournamentId, adminAddress) {
  try {
    console.log("addAdmin walletaddress: " + walletAddress);
    console.log('addAdmin tournamentId: ' + tournamentId)
    console.log('addAdmin adminAddress: ' + adminAddress)

    const result = await wwcTournamentContract.methods.addTournamentAdmin(
      tournamentId,
      adminAddress
    ).send({from: walletAddress});
    console.log('addAdmin send result: ', result);
    console.log('addAdmin send hash: ', result.transactionHash);
    return result.transactionHash;
  } catch (error) {
    console.error('Error calling addAdmin function:', error);
    return -1
  } 
}

export async function removeAdmin(walletAddress, tournamentId, adminAddress) {
  try {
    console.log("removeAdmin walletaddress: " + walletAddress);
    console.log('removeAdmin tournamentId: ' + tournamentId)
    console.log('removeAdmin adminAddress: ' + adminAddress)

    const result = await wwcTournamentContract.methods.removeTournamentAdmin(
      tournamentId,
      adminAddress
    ).send({from: walletAddress});
    console.log('removeAdmin send result: ', result);
    console.log('removeAdmin send hash: ', result.transactionHash);
    return result.transactionHash;
  } catch (error) {
    console.error('Error calling removeAdmin function:', error);
    return -1
  } 
}

export async function createTournament(walletAddress, numRounds, athleteAddresses) {
  try {
    console.log("createTournament walletaddress: " + walletAddress);
    console.log('createTournament numRounds: ' + numRounds)
    console.log('createTournament addresses: ' , athleteAddresses)

    const result = await wwcTournamentContract.methods.createTournament(
      numRounds,
      athleteAddresses
    ).send({from: walletAddress});
    console.log('createTournament send result: ', result);
    console.log('createTournament send hash: ', result.transactionHash);
    return result.transactionHash;
  } catch (error) {
    console.error('Error calling createTournament function:', error);
    return -1
  }
}

export async function setTournamentGames(_walletAddress, _tournamentId, _roundNum, _addresses) {
  let result;
  for (let gameNum = 0; gameNum < _addresses.length / 2; gameNum++) {
    console.log('setTournamentGames tournamentId='+_tournamentId+' roundNum='+_roundNum+' gameNum='+gameNum+
      ' a='+_addresses[gameNum * 2]+' b='+_addresses[gameNum * 2 + 1]+' w='+_walletAddress);
    result = await wwcTournamentContract.methods.updateAthletes(
      _tournamentId,
      _roundNum, // 0..
      gameNum, // 0..
      _addresses[gameNum * 2],
      _addresses[gameNum * 2 + 1],
      address0
    ).send({from: _walletAddress });
    console.log('setTournamentGames result: ', result);
  } 
}

export async function setRoundAndNextRound(_walletAddress, _tournamentId, _roundId, _athleteAddresses, _winnerAddresses=[]) {
  if (_winnerAddresses.length === 0) {
    _winnerAddresses = new Array(_athleteAddresses.length / 2).fill(address0);
  }

  console.log('setRoundAndNextRound _walletAddress='+_walletAddress+' tournamentId='+_tournamentId+' roundId='+_roundId+
    ' athleteAdresses=',_athleteAddresses, ' winnerAddresses: ', _winnerAddresses);
  
  const result = await wwcTournamentContract.methods.setRoundAndNextRound(
    _tournamentId,
    _roundId,
    _athleteAddresses,
    _winnerAddresses
  ).send({from: _walletAddress });
  console.log('setRoundAndNextRound result: ', result);
}

export async function getNumroundsForTournament(tournamentId) {
  try {
    // Call the getNumRounds function from your contract
    const numRounds = await wwcTournamentContract.methods.getNumRounds(tournamentId).call();
    console.log('getNumroundsForTournament numRounds: ', numRounds);
    return numRounds;
  } catch (error) {
    console.error("Error in getNumroundsForTournament: ", error);
  }
}

export async function getAthletesForTournament(tournamentId) {
  try {
    // Call the getAthletesForTournament function from your contract
    const athleteAddresses = await wwcTournamentContract.methods.getAthletesForTournament(tournamentId).call();
    console.log('getAthletesForTournament athleteAddresses: ', athleteAddresses);
    return athleteAddresses;
  } catch (error) {
    console.error("Error in getAthletesForTournament: ", error);
  }
}

export async function getAthletesInARound(_tournamentId, _roundNum) {
  try {
    console.log('getAthletesInARound tournamentId='+_tournamentId + ' roundNum='+_roundNum);
    // Call the getAthletesInARound function from your contract
    const result = await wwcTournamentContract.methods.getAthletesInARound(_tournamentId, _roundNum).call();
    console.log('getAthletesInARound result: ', result);
    let athleteAddresses, winnerAddresses;
    if (Array.isArray(result)) {
      [athleteAddresses, winnerAddresses] = result;
    } else {
      athleteAddresses = result[0];
      winnerAddresses = result[1];
    }
    console.log('getAthletesInARound athleteAddresses: ', athleteAddresses);
    console.log('getAthletesInARound winnerAddresses: ', winnerAddresses);
    return [athleteAddresses, winnerAddresses];
  } catch (error) {
    console.error("Error in getAthletesInARound: ", error);
  }
}



// ViewTournamentPage.js
import React, { useState, useEffect } from 'react';
import { getNumroundsForTournament, getAthletesForTournament } from './util/interactTournament';

function ViewAthletesPage() {
  const [tournamentId, setTournamentId] = useState(parseInt(localStorage.getItem('tournamentId')) || 0);
  const [numRounds, setNumRounds] = useState(0);
  const [athleteAddresses, setAthleteAddresses] = useState([]);

  useEffect(() => {
    if (tournamentId) {
      console.log('view Tournament for tournamentId='+tournamentId);
      getNumroundsForTournament(tournamentId).then(numRounds => {
        console.log('getNumroundsForTournament: '+numRounds);
        setNumRounds(numRounds);
      });
      getAthletesForTournament(tournamentId).then(addresses => {
        console.log('getAthletesForTournament: ', addresses);
        setAthleteAddresses(addresses);
        localStorage.setItem('AthletesAddresses', JSON.stringify(addresses));
      });
    }
  }, [tournamentId]);

  const handleGetAthletesSubmit = (event) => {
    event.preventDefault();
    localStorage.setItem('tournamentId', tournamentId);
  };

  return (
    <div id="container">
      <h1>View Athletes</h1>
      <form onSubmit={handleGetAthletesSubmit}>
        <label>
          For Tournament Id:
          <input type="number" value={tournamentId} onChange={e => setTournamentId(e.target.value)} min="0" />
        </label>
        <button type="submit">Get Athletes</button>
      </form>

      <p>Number of rounds: {numRounds}</p>
      <h2>Athlete Addresses:</h2>
      {athleteAddresses.map((address, index) => (
        <p key={index}>{index}: {address}</p>
      ))}
    </div>
  );
}

export default ViewAthletesPage;

require("dotenv").config() // only read starting with "REACT_APP_" !
const alchemyKey = process.env.REACT_APP_ALCHEMY_KEY
console.log('Alchemy_key: ' + alchemyKey)
const { createAlchemyWeb3 } = require("@alch/alchemy-web3")
const web3 = createAlchemyWeb3(alchemyKey)

export const address0 = '0x0000000000000000000000000000000000000000';

export function showjson(json) {
  return (json) ? JSON.stringify(json).substring(0,80)+'...' : ''
}

export async function getBalanceInEth(address) {
  const balanceInWei = await web3.eth.getBalance(address)
  const balanceInEth = web3.utils.fromWei(balanceInWei)
  console.log('balance in eth: ', balanceInEth)
  return 1*balanceInEth // as value
}

export const connectWallet = async () => {
  if (window.ethereum) {
    try {
      const addressArray = await window.ethereum.request({
        method: "eth_requestAccounts", // Open Wallet to connect..
      })
      const address = addressArray[0]
      const balanceInEth = await getBalanceInEth(address)
      const obj = {
        address: address,
        balance: balanceInEth,
        status: "👆🏽 Write a message in the text-field above.",
      }
      return obj
    } catch (err) {
      return {
        address: "",
        balance: 0,
        status: "😥 " + err.message,
      }
    }
  } else {
    return {
      address: "",
      balance: 0,
      status: (
        <span>
          <p>
            {" "}
            🦊 <a target="_blank" rel="noreferrer" href={`https://metamask.io/download.html`}>
              You must install MetaMask, a virtual Ethereum wallet, in your
              browser.
            </a>
          </p>
        </span>
      ),
    }
  }
};

export const disconnectWallet = async () => {
  // https://stackoverflow.com/questions/66866817/is-there-any-way-to-initiate-a-disconnect-request-to-the-metamask-wallet
  if (window.ethereum) {
    await window.ethereum.request({
      method: "eth_requestAccounts",
      params: [{ eth_accounts: {} }]
    })
    return {
      address: "",
      balance: 0,
      status: "Wallet disconnected!",
    }
  } else {
    return {
      address: "",
      balance: 0,
      status: "No wallet to disconnect!",
    }
  }
}

export const getCurrentWalletConnected = async () => {
  if (window.ethereum) {
    try {
      const addressArray = await window.ethereum.request({
        method: "eth_accounts", // already connected Wallet..
      })
      const address = addressArray[0]
      console.log('getCurrentWalletConnected address='+address);
      const balanceInEth = await getBalanceInEth(address)
      console.log('getCurrentWalletConnected balanceInEth='+balanceInEth);
      if (address.length > 0) {
        return {
          address: address,
          balance: balanceInEth,
          status: "👆🏽 Write a message in the text-field above.",
        }
      } else {
        return {
          address: "",
          balance: 0,
          status: "🦊 Connect to MetaMask using the top right button.",
        }
      }
    } catch (err) {
      return {
        address: "",
        balance: 0,
        status: "😥 " + err.message,
      }
    }
  } else {
    return {
      address: "",
      balance: 0,
      status: (
        <span>
          <p>
            {" "}
            🦊 <a target="_blank" rel="noreferrer" href={`https://metamask.io/download.html`}>
              You must install MetaMask, a virtual Ethereum wallet, in your
              browser.
            </a>
          </p>
        </span>
      ),
    }
  }
};

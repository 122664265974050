// EarningPage.js
import React, { useState, useEffect, useCallback } from 'react';
//import './AdminPage.css';
import {
  getCurrentWalletConnected,
} from "./util/interactGeneral";
import {
  wwcTournamentContract,
  addAdmin,
  removeAdmin,
  fetchAdmins,
} from "./util/interactTournament";

function AdminPage() {
  //state variables
  const [walletAddress, setWallet] = useState("");
  const [newAdminAddress, setNewAdminAddress] = useState('');
  //const [walletBalance, setWalletBalance] = useState(0);
  //const [totalSupply, setTotalSupply] = useState(0);
  const [status, setStatus] = useState("");
  const [tournamentId, setTournamentId] = useState(parseInt(localStorage.getItem('tournamentId')) || 0);
  const [tournamentAdmins, setTournamentAdmins] = useState([].fill(''));

  const addSmartContractListenerCallback = useCallback(() => {
    wwcTournamentContract.events.AdminsUpdated({}, (error, data) => {
      if (error) {
        setStatus("😥 " + error.message);
        console.log('addSmartContractListener AdminsUpdated error: ' + error.message);
      } else {
        console.log('addSmartContractListener AdminsUpdated event data: ', data);
        const tId = parseInt(data.returnValues[0]);        
        console.log('addSmartContractListener AdminsUpdated event Id: '+tId);
        setTournamentId(tId);
        const addresses = data.returnValues[1];
        console.log('addSmartContractListener AdminsUpdated event addresses: ', addresses);
        setTournamentAdmins(addresses);
        localStorage.setItem('tournamentId', tId.toString()); // survives Browser refresh or URL reload / as string
        //localStorage.setItem('numRounds', numRounds.toString());
        //localStorage.setItem('athleteAddresses', JSON.stringify(athleteAddresses));
        //console.log('addSmartContractListener AdminsUpdated tournamentId: ' , tournamentId);
      }
    })
  }, [setTournamentId, setTournamentAdmins]);

  /*const addWalletListenerCallback = useCallback(() => {
    if (window.ethereum) {
      window.ethereum.on("accountsChanged", async (accounts) => {
        if (accounts.length > 0) {
          const account = accounts[0]
          setWallet(account)
          console.log('addWalletListener walletAddress: ' + walletAddress)
          //setWalletBalance(await getBalanceInEth(account))
          //console.log('addWalletListener balance: ' + walletBalance)
          //setTotalSupply(await getTotalSupply())
          //console.log('addWalletListener totalSuuply: ' + totalSupply)
          setStatus("👆🏽 Fill in the fields.")
          console.log('addWalletListener status: ' + status)
        } else {
          setWallet("")
          console.log('addWalletListener NO walletAddress ! Connect wallet please..')
          //setWalletBalance(0)
          //setTotalSupply(0)
          setStatus("🦊 Connect to MetaMask using the top right button.")
        }
      })
    } else {
      setStatus(
        <p>
          {" "}
          🦊 <a target="_blank" rel="noreferrer" href={`https://metamask.io/download.html`}>
            You must install MetaMask, a virtual Ethereum wallet, in your browser.
          </a>
        </p>
      )
    }
  }, [status, walletAddress/*, walletBalance, totalSupply * /])
*/

  const updateAdminAddressesCallback = useCallback(async () => {
  //}
  //const updateAdminAddresses = async (tId) => {
    const tId = tournamentId;
    console.log('updateAdminAddressesCallback tId: ' + tId)
    const addresses = await fetchAdmins(tId);
    console.log('updateAdminAddressesCallback adresses: ' , addresses);
    setTournamentAdmins(addresses);
    //console.log('updateAdminAddressesCallback tournamentAdmins: ', tournamentAdmins);
  }, [setTournamentAdmins, tournamentId]);

  //called only once
  useEffect(() => {
    async function fetchData() {
      try {
        addSmartContractListenerCallback();

        const { address } = await getCurrentWalletConnected();
        setWallet(address);
        console.log('useEffect address: ' + walletAddress);

        //addWalletListenerCallback();
        updateAdminAddressesCallback();
      } catch (error) {
        console.log(`Error: ${error}`);
      }
    }
    fetchData();
  }, [walletAddress, /*tournamentAdmins,*/ updateAdminAddressesCallback, /*tournamentId, /*addWalletListenerCallback,*/ addSmartContractListenerCallback]);

  const handleAddAdminSubmit = async (e) => {
    e.preventDefault();
    const TxHash = await addAdmin(walletAddress, tournamentId, newAdminAddress);
    console.log('handleAddAdminSubmit TxHash: ' , TxHash);
    setStatus('Tx Hash: ' + TxHash);
  };

  const handleChangeTournamentId = async (e) => {
    e.preventDefault();
    const tId = e.target.value;
    console.log('handleChangeTournamentId tournamentId: ' + tId);
    setTournamentId(tId);
    updateAdminAddressesCallback();
  }

  const handleRemoveAdmin = async (removeAdminAddress) => {
    try {
      const TxHash = await removeAdmin(walletAddress, tournamentId, removeAdminAddress);
      console.log('handleAddAdminSubmit TxHash: ' , TxHash);
      setStatus('Tx Hash: ' + TxHash);
      //fetchAdmins(); // Refetch the updated list of admins
      //updateAdminAddressesCallback();
    } catch (error) {
      console.error('Error removing admin:', error);
    }
  };

  return (
    <div id="container">
      <h1>Admin Page</h1>
      <p>WalletAddress: {walletAddress}</p>

      <form onSubmit={handleAddAdminSubmit} className="addadmin-form">
        <div className="form-control">
          <label>
            For Tournament Id:
            <input type="number" value={tournamentId} onChange={e => handleChangeTournamentId(e)} min="0" />
          </label>
        </div>
        <div className="form-control">
          <label>
          Admin's Ethereum address:
          </label>
          <input
            type="text"
            id="newAdminAddress"
            value={newAdminAddress}
            onChange={(e) => setNewAdminAddress(e.target.value)}
          />
        </div>
        <div className="form-control">
          <p id="status">{tournamentId} : {status}</p>
          <button type="submit">Send</button>
        </div>
      </form>

      <div>
        <h2>Admins for Tournament: {tournamentId}</h2>
        <ul>
          {tournamentAdmins.map((admin, index) => (
            <li key={index}>
              {admin}
              <button onClick={() => handleRemoveAdmin(admin)} style={{marginLeft: '10px', marginTop: '0', background: 'none', color: 'blue', textDecoration: 'underline', border: 'none', cursor: 'pointer'}}>
                Remove
              </button>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}

export default AdminPage;

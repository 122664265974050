// EarningPage.js
function EarningPage() {
  return (
    <div id="container">
      <h1>Earning Page</h1>
    </div>
  );
}

export default EarningPage;

require("dotenv").config() // only read starting with "REACT_APP_" !
const alchemyKey = process.env.REACT_APP_ALCHEMY_KEY
console.log('Alchemy_key: ' + alchemyKey)
const { createAlchemyWeb3 } = require("@alch/alchemy-web3")
const web3 = createAlchemyWeb3(alchemyKey)
const { showjson } = require('./interactGeneral')

const wwcTournamentAddress = process.env.REACT_APP_CONTRACT_WWCTOURNAMENT_ADDRESS
console.log('wwcTournamentAddress: ' + wwcTournamentAddress)

const wwcTournamentAbi = require("../contract-abis/wwcTournament-abi.json")
console.log('wwcTournamentAbi: ' + showjson(wwcTournamentAbi))

export const wwcTournamentContract = new web3.eth.Contract (
  wwcTournamentAbi, wwcTournamentAddress
)

export const loadCurrentMessage = async () => { 
  const message = await wwcTournamentContract.methods.message().call()
  console.log('loadCurrentMessage: '+message)
  return message
};

export async function mintAthlete(recipient/*, uri*/) {
  try {
    console.log('mintAthlete recipient: ' + recipient)
    //console.log('votingNFTMint uri: ' + uri)
    const mintResult = await wwcTournamentContract.methods.mintAthlete(
      recipient,
      //uri
    ).call();
    console.log('mintAthlete: ', mintResult);
    return mintResult
  } catch (error) {
    console.error('Error calling mintAthlete function:', error);
    return -1
  }
}

export const updateMessage = async (address, message) => {
  if (!window.ethereum || address === null) {
    return {
      status:
        "💡 Connect your MetaMask wallet to update the message on the blockchain.",
    }
  }

  if (message.trim() === "") {
    return {
      status: "❌ Your message cannot be an empty string.",
    }
  }

  //set up transaction parameters
  const transactionParameters = {
    to: wwcTournamentAddress, // Required except during contract publications.
    from: address, // must match user's active address.
    data: wwcTournamentContract.methods.updateMessage(message).encodeABI(),
  }

  //sign the transaction
  try {
    const txHash = await window.ethereum.request({
      method: "eth_sendTransaction",
      params: [transactionParameters],
    })
    return {
      status: (
        <span>
          ✅{" "}
          <a target="_blank" rel="noreferrer" href={`https://goerli.etherscan.io/tx/${txHash}`}>
            View the status of your transaction on Etherscan!
          </a>
          <br />
          ℹ️ Once the transaction is verified by the network, the message will be
          updated automatically.
        </span>
      ),
    }
  } catch (error) {
    return {
      status: "😥 " + error.message,
    }
  }
};
